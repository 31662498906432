import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import HomeClients from "../components/HomeClients";
import ACVSASBanner from "../components/ACVSASBanner";
import ACFeature from "../components/ACFeature";
import ACComparison from "../components/ACComparison";
import ACMan from "../components/ACMan";
import ACPrivacy from "../components/ACPrivacy";
import SwitchContact from "../components/SwitchContact";

const homeManContent = [
  "No hidden commissions or markups",
  "No messy mailouts",
  "No data sharing required",
  "No manual client grouping required",
  "No manual opt ins",
];

const ACVsASPage = ({ data }) => {
  const { testimonials } = data;
  return (
    <Layout
      bg="home-bg"
      banner={<ACVSASBanner />}
      isDisplayBottomPartner={true}
    >
      <ACComparison />
      <ACFeature />
      <ACMan
        textDark
        title="Get started with AuditCover in under 60 seconds"
        content={homeManContent}
        hasButton={true}
        buttonLabel="Learn more"
        buttonLink="#ac-as-contact-form"
      />
      <ACPrivacy />
      <HomeClients data={testimonials?.edges} />
      <SwitchContact formId="ac-as-contact-form" />
    </Layout>
  );
};

export default ACVsASPage;

export const contentQuery = graphql`
  query {
    banner: markdownRemark(
      frontmatter: { templateKey: { eq: "content-banner" } }
    ) {
      id
      frontmatter {
        subheader
        header
        header2
        ctalinktext
        socialproof
      }
    }
    testimonials: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "testimonials" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            photo {
              publicURL
            }
            person
            company
            type
            title
            contents
          }
        }
      }
    }
  }
`;
