import React from "react";
import PropTypes from "prop-types";

const ACMan = ({
  textDark = false,
  title,
  desc,
  content,
  hasBottomRadius = false,
  hasButton = false,
  buttonLabel = "Get a smarter quote",
  buttonLink,
}) => {
  const classes = textDark ? "text-black" : "text-white";

  return (
    <div
      className={`ac-man ${hasBottomRadius ? "bottom-radius" : ""} ${
        textDark ? "" : "woman"
      }`}
    >
      <div className="content nu-c">
        <div className={`nu-man-txt ${classes}`}>
          <h1>{title}</h1>
          <p>{desc}</p>

          {content?.map((item) => {
            return (
              <p className="nu-instruction" key={item}>
                <img
                  src="/images/new-landing-page/tick-icon.svg"
                  alt="tick"
                  width={16}
                  height={16}
                  loading="lazy"
                />
                {item}
              </p>
            );
          })}

          {hasButton && (
            <a
              aria-current="page"
              className="nu-man--btn cta cta-mirror w-button"
              href={buttonLink}
            >
              {buttonLabel}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

ACMan.propTypes = {
  textDark: PropTypes.bool,
  title: PropTypes.string.isRequired,
  hasBottomRadius: PropTypes.bool,
  hasButton: PropTypes.bool,
  contentData: PropTypes.array,
  buttonLabel: PropTypes.string,
  buttonLink: PropTypes.string,
};

export default ACMan;
