import React, { useState } from "react";

const data = [
  {
    id: "id_client_distribution",
    title: "Client Distribution",
    desc: "Audit Shield firms are required to broadcast the offering to 85% or more of business and SMSF clients regardless of their interest or likely risk. AuditCover lets you decide.",
    acTxt: "Flexible",
    asTxt: "Min. 85% of business and SMSF clients",
  },
  {
    id: "id_waiting_periods",
    title: "Waiting Periods",
    desc: "Audit Shield can’t be legally offered for 6 weeks after signing a new client. With AuditCover, clients can be covered from the start.",
    acTxt: "No waiting periods",
    asTxt: "6 weeks",
  },
  {
    id: "id_grouping",
    title: "Grouping",
    desc: "Audit Shield requires a minimum of 25% ownership between insured entities. With AuditCover, you can insure all client group members on one policy, regardless of shareholding.",
    acTxt: "No limits",
    asTxt: "25% ownership required",
  },
  {
    id: "id_turnover_prefilling",
    title: "Turnover Prefilling",
    desc: "Audit Shield practices must prefill turnover data, adding significant admin time and effort. With AuditCover turnover information can be provided by the client.",
    acTxt: "tick",
    asTxt: "cross",
  },
  {
    id: "id_automated_renewals",
    title: "Automated Renewals",
    desc: "Audit Shield practices must ‘opt in’ clients each year. AuditCover offers automated renewals directly to the client with no accountant involvement.",
    acTxt: "tick",
    asTxt: "cross",
  },
  {
    id: "id_automated_refunds",
    title: "Automated Refunds",
    desc: "Audit Shield firms are responsible for refunding clients who leave mid-term. AuditCover handles all refunds.",
    acTxt: "tick",
    asTxt: "cross",
  },
  {
    id: "id_limit_of_cover_options",
    title: "Limit of Cover Options",
    desc: "Audit Shield‘s standard offer contains only one limit of cover per cohort.",
    acTxt: "tick",
    asTxt: "cross",
  },
  {
    id: "id_smsf_cover",
    title: "Campaign Scheduling",
    desc: "Audit Shield sends one campaign to all clients each year. AuditCover’s digital platform automates campaign scheduling to the client at intervals you choose.",
    acTxt: "tick",
    asTxt: "cross",
  },
];

const renderFeatureContent = (metaData) => {
  switch (metaData) {
    case "tick":
      return (
        <img
          src="/images/new-landing-page/tick.svg"
          alt="tick"
          className="tick-icon"
        />
      );
    case "cross":
      return (
        <img
          src="/images/new-landing-page/cross.svg"
          alt="cross"
          className="cross-icon"
        />
      );
    case "Min. 85% of business and SMSF clients":
      return (
        <>
          Min. 85% of business and SMSF <br /> clients
        </>
      );
    default:
      return metaData;
  }
};

const ACFeature = () => {
  const [collapsedStates, setCollapsedStates] = useState(
    data.reduce((acc, item) => ({ ...acc, [item.id]: true }), {})
  );

  const toggleCollapse = (id) => {
    setCollapsedStates((prevStates) => ({
      ...prevStates,
      [id]: !prevStates[id],
    }));
  };

  return (
    <div className="feature-container bottom-radius" id="features">
      <div className="content feature-c">
        <div className="comparison-container">
          <div className="full-width demo">
            {data.map((item) => (
              <div className="w-dyn-item" key={item.id}>
                <div className="feature-table-item">
                  <div className="vertical-feature-stacker">
                    <div
                      className="horizontal-feature-stacker"
                      onClick={() => toggleCollapse(item.id)}
                    >
                      <h3>{item.title}</h3>

                      <img
                        src={
                          collapsedStates[item.id]
                            ? "/images/new-landing-page/plus.svg"
                            : "/images/new-landing-page/minus.svg"
                        }
                        alt="minus"
                      />
                    </div>

                    <div
                      className="concealer"
                      style={{
                        opacity: collapsedStates[item.id] ? 0 : 1,
                        display: collapsedStates[item.id] ? "none" : "flex",
                        width: "100%",
                        height: collapsedStates[item.id] ? 0 : "auto",
                      }}
                    >
                      <div className="item-text">{item.desc}</div>
                    </div>
                  </div>

                  <div className="vertical-stacker centred align-middle feat-comp-txt">
                    {renderFeatureContent(item.acTxt)}
                  </div>

                  <div className="vertical-stacker centred align-middle feat-comp-txt">
                    {renderFeatureContent(item.asTxt)}
                  </div>

                  {/*Mobile*/}
                  <div className="ac-mobile">
                    <div className="ac-badge"></div>
                    <div>{renderFeatureContent(item.acTxt)}</div>
                  </div>

                  <div className="ac-mobile as-mobile">
                    <div className="as-badge">Audit Shield</div>
                    <div>{renderFeatureContent(item.asTxt)}</div>
                  </div>
                </div>
                <div className="feature-line"></div>
              </div>
            ))}

            <div className="signup-prompt-mobile">
              <a href="#ac-as-contact-form" className="join-btn">
                Join for free
              </a>

              <a href="#ac-as-contact-form" className="book-demo-btn">
                or book a demo
              </a>
            </div>
          </div>

          <div className="ac-column">
            <div className="ac-badge"></div>
            <div className="signup-prompt">
              <a href="#ac-as-contact-form" className="join-btn">
                Join for free
              </a>

              <a href="#ac-as-contact-form" className="book-demo-btn">
                or book a demo
              </a>
            </div>
          </div>

          <div className="as-column">
            <div className="as-badge">Audit Shield</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ACFeature;
