import React from "react";
import { Link, withPrefix } from "gatsby";

const ACVSASBanner = () => {
  return (
    <div className="banner">
      <div className="content">
        <div className="banner-wr">
          <div className="banner-txt text-white nu-c">
            <h1>AuditCover vs Audit Shield</h1>
            <p className="banner-p text-white">
              See why AuditCover is better for you and your clients than Audit
              Shield
            </p>
            <div className="banner-btn-group">
              <a
                href="#features"
                className="cta long w-button cta-top-mirror btn-primary"
              >
                Compare features
              </a>

              <Link
                to="#ac-as-contact-form"
                className="cta long w-button cta-top-mirror btn-secondary"
              >
                Book a demo
              </Link>
            </div>
          </div>
          <div className="banner-img nu-c ac-vs-as-banner">
            <img
              src={withPrefix(
                "images/new-landing-page/as-vs-ac-banner-image.png"
              )}
              alt="banner"
              loading="lazy"
              style={{
                marginLeft: "2rem",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ACVSASBanner;
