import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";

import HomeClientItem from "./HomeClientItem";

const settings = {
  autoplay: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  dots: true,
  className: "slider slider-two",
};

const HomeClients = ({ data, isDark = false }) => {
  return (
    <div className={isDark ? "nu-client-dark" : ""}>
      <div className="content nu-c">
        <div className="nu-client--title">WHAT OUR CLIENTS SAY</div>
        <Slider {...settings}>
          {data.map(({ node }) => (
            <div key={node?.id}>
              <HomeClientItem
                avatar={node?.frontmatter?.photo?.publicURL}
                title={node?.frontmatter?.title}
                subtitle={node?.frontmatter?.contents}
                authorName={node?.frontmatter?.person}
                authorTitle={node?.frontmatter?.company}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

HomeClients.propTypes = {
  data: PropTypes.array.isRequired,
  isDark: PropTypes.bool,
};

export default HomeClients;
