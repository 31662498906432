import React from "react";

const ACPrivacy = () => {
  return (
    <div className="nu-privacy">
      <div className="content nu-c">
        <div className="nu-privacy-txt">
          <h1>Why AuditCover?</h1>

          <p>
            Providing clients with a tax audit insurance solution can suck
            valuable hours away from the work that really matters to your
            practice. AuditCover is a new and improved way to offer top quality
            tax audit insurance to clients without the admin load and compliance
            burdens of the past! This means your clients can get covered and you
            get paid quickly on all eligible claims.
          </p>

          <p>
            AuditCover is a zero-cost and low touch solution for progressive
            practices looking to automate their tax audit insurance solution.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ACPrivacy;
