import React from "react";

const ACComparison = () => {
  return (
    <div className="content">
      <div className="ac-comparison-container">
        <div className="ac-comparison-content">
          <div className="left">
            <img src="/images/smarter.png" alt="q&b" loading="lazy" />
          </div>

          <div className="right">
            <div className="logo-comparison">
              <img
                src="/images/new-landing-page/auditcover.svg"
                alt="auditcover"
                loading="lazy"
              />
              <div className="vs">vs</div>
              <div className="auditshield-text">Audit Shield</div>
            </div>

            <p>
              AuditCover is a fully digital tax audit insurance solution. It
              covers taxpayers directly, and keeps them covered without any
              admin work from you! Audit Shield requires you to sign your
              clients up. Every. Single. Year.
            </p>

            <p>
              It’s literally groundhog day every year when renewals roll around.
            </p>

            <a href="/switching-from-audit-shield" className="switch-prompt">
              <div className="horizontal-stacker">
                <img
                  src="/images/new-landing-page/hand.svg"
                  alt="hand"
                  className="switch-hand"
                />

                <div className="vertical-stacker">
                  <div className="switch-small-text">STEP-BY-STEP GUIDE</div>
                  <div className="switch-big-text">
                    How to switch from Audit Shield to AuditCover
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ACComparison;
